import {
  Container,
  Content,
} from './styles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SearchComponent from '../../components/searchContent/Search';
import RequestComponent from '../../components/requestsContent/Requests';
import { fetchData } from '../../config/api';

function Main() {

  const [researched, setSearched] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const { idcompany, iduser } = useParams();
  const navigate = useNavigate();

  const companyMap = {
    'femme': '9c3d58d8-d8a3-4cfa-b412-919489be8545',
    'koji': '9c3d7f92-3bd5-4c27-8408-8c90c2f375c7',
    'avecvous': '9c3d7f0f-d91f-4622-ae09-f338a2e39bc2'
  };

  const companyID = companyMap[idcompany] || idcompany;

  useEffect(() => {
    const fetchOrdens = async () => {
      if (iduser && companyID) {
        const orders = await fetchData(companyID, iduser);
        setFilteredOrders(orders);
        setSearched(true);
      }
    }

    fetchOrdens();
  }, [iduser, companyID]);

  const handleSearch = async (inputValue) => {
    if (!companyID) return;
    const orders = await fetchData(companyID, inputValue);
    setFilteredOrders(orders);
    setSearched(true);
    navigate(`/${idcompany}/${inputValue}`);
  };

  const handleInitialSearch = () => {
    setSearched(false);
  };

  return (
    <Container>
      <Content>
        <SearchComponent onSearch={handleSearch} handleInitialSearch={handleInitialSearch} />
        <RequestComponent onClickSearchOrdens={researched} filteredOrders={filteredOrders} />
      </Content>
    </Container>
  );
}

export default Main;