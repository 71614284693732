import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    body {
        margin: 0;
        font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style: none;
    }

    code {
        font-family: 'Poppins',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }

    *{
        scrollbar-width: 10px;
        scrollbar-color: #8c8c8c;
    }

    *::-webkit-scrollbar{
        width: 5px;
        }
        
    *::-webkit-scrollbar-thumb{
        background-color: #7a7a7a;
        border-radius: 20px;
    }

    input[type=number]::-webkit-inner-spin-button { 
        -webkit-appearance: none;
    }
    
    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
    }

    ::-webkit-input-placeholder {
        color: #8f8f8fe1;
        font-weight: 500;
        font-size: 14px;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: #bdbdbdc5;
        font-weight: 500; 
    }

    ::-moz-placeholder {  /* Firefox 19+ */
        color: #bdbdbdc5;
        font-weight: 500; 
    }

    :-ms-input-placeholder {  
        color: #bdbdbdc5;
        font-weight: 500; 
    }
`