import Main from './pages/Home/index.js';
import { Route, Routes, BrowserRouter} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/:idcompany" element={<Main/>} />
        <Route path="/:idcompany/:iduser" element={<Main/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;