import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: auto;
    min-height: 100dvh;
    display: flex;
    background-color: #f3f3f3;
`;

export const Content = styled.div`
    width: 100%;
    height: auto;
`;