import {
    ButtonCleanInput,
    ButtonSearchContainer,
    ButtonSearchContent,
    ButtonSend,
    ButtonSendContent,
    ButtonsArea,
    IconSearchContent,
    InputSearch,
    Logo,
    LogoContainer,
    SearchContent,
    ButtonText,
} from './styles';
import {RequestText} from '../../components/requestsContent/styles';
import React, { useRef, useState } from 'react';

import { Search, CircleX } from 'lucide-react';
import { fetchData } from '../../config/api';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from '../loader';

export default function SearchComponent({ onSearch, handleInitialSearch }) {
    
    const [inputValue, setInputValue] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [loader, setLoader] = useState();
    const [requestFailed, setRequestFailed] = useState(false);
    const inputRef = useRef(null);
    const { idcompany } = useParams();
    const navigate = useNavigate();

    const companyMap = {
        'femme': '9c3d58d8-d8a3-4cfa-b412-919489be8545',
        'koji': '9c3d7f92-3bd5-4c27-8408-8c90c2f375c7',
        'avecvous': '9c3d7f0f-d91f-4622-ae09-f338a2e39bc2'
      };

    const companyID = companyMap[idcompany] || idcompany;

    // Limita caracteres para 11 e remove caracteres não númericos
    const handleInputChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        if (value.length <= 11) {
            setInputValue(value);
        }
    };

    const handleSearchOrdens = async () => {
        setLoader(true);

        if(inputValue === '' || inputValue.length <= 10 || !idcompany) {
            setLoader(false);
            setRequestFailed(true);
            return;
        }

        try {
            await fetchData(companyID, inputValue);
            await onSearch(inputValue);
            navigate(`/${idcompany}/${inputValue}`);
        } catch (error) {
            console.log('Erro ao buscar os pedidos', error);
        } finally {
            setLoader(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearchOrdens();
        }
    };

    function handleInputOnfocus() {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleInputClean = () => {
        handleInitialSearch();
        setRequestFailed(false);
        setInputValue('');
    };

    const logoMap = {
        'femme': '/femmeLogo.png',
        'koji': '/kojiLogo.png',
        'avecvous': '/avecvousLogo.png',
        '': '/logoDefault.png',
    }

    const logoPage = logoMap[idcompany] || './logoDefault.png';

    return (
        <SearchContent>
            <LogoContainer>
                <a href={idcompany ? `/${idcompany}` : '/'}>
                    <Logo src={logoPage} alt=''/>
                </a>
            </LogoContainer>
            <ButtonSearchContainer>
                <ButtonsArea>
                    <ButtonSearchContent>
                        <IconSearchContent onClick={handleInputOnfocus} style={{ cursor: 'pointer' }} $isInputFocused={isInputFocused}>
                            <Search 
                                size={15} 
                                color='#37517E' 
                                strokeWidth={3} 
                            />
                        </IconSearchContent>
                        <InputSearch
                            id="search-input"
                            name="search"
                            autoComplete='off'
                            type='number'
                            value={inputValue}
                            placeholder='Digite seu CPF ou Telefone'
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            required
                            ref={inputRef}
                            onFocus={() => setIsInputFocused(true)}
                            onBlur={() => setIsInputFocused(false)}
                        />
                        <ButtonCleanInput $isInputFocused={isInputFocused}>
                            {inputValue > 0 &&
                                (<CircleX 
                                    size={18} 
                                    color='#37517E' 
                                    strokeWidth={2} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={handleInputClean} 
                                />)
                            }
                        </ButtonCleanInput>
                    </ButtonSearchContent>
                    <ButtonSendContent>
                        <ButtonSend onClick={handleSearchOrdens}>
                            <ButtonText>Buscar Pedidos</ButtonText>
                        </ButtonSend>
                    </ButtonSendContent>
                </ButtonsArea>
            </ButtonSearchContainer>
            {requestFailed && <RequestText>CPF ou Telefone inválido.</RequestText>}
            {loader && <Loader />}
        </SearchContent>
    )
}