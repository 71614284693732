import axios from "axios";

export async function fetchData(idcompany, iduser) {
    if(!idcompany || !iduser) {
        console.log('ID da empresa ou de usuário não estão definidos');
        return [];
    }
    
    const baseURL = 'https://crm.wehsoft.com/api';
    const url = `${baseURL}/${idcompany}/${iduser}`;

    try {
        const response = await axios.get(url);
        if (response.status === 200) {
            return response.data;
        } else {
            console.error('Resposta inválida da API', response);
            return [];
        }
    } catch (error) {
        console.log('Erro ao carregar os dados', error);
        return [];
    }
}