import styled from 'styled-components';

export const SearchContent = styled.div`
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const LogoContainer = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 20px;
`;

export const Logo = styled.img`
    object-fit: cover;
    max-width: 200px;

    &:hover {
        opacity: 0.75;
        cursor: pointer;
    }
`;

export const ButtonSearchContainer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
        height: 150px;
    }
`;

export const ButtonsArea = styled.div`
    width: 100%;
    max-width: 550px;
    padding: 0 10px;
    height: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0px 0px 1px #86868696;

    @media (max-width: 600px) {
        flex-direction: column; 
        width: 90%;
    }
`;

export const ButtonSearchContent = styled.form`
    width: 60%;
    padding: 0 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 0px 1px #86868696);

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const InputSearch = styled.input`
    width: 70%;
    height: 45px;
    border: none;
    border-left: 1px solid #e7e7e7;
    border-right: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
    color: #2c2c2c;
    -webkit-appearance: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
        border-top: 1px solid #9bafd3;
        border-bottom: 1px solid #9bafd3;
    }

    @media (max-width: 600px) {
        width: 85%;
    }
`;

export const IconSearchContent = styled.div`
    width: 40px;
    height: 45px; 
    border: none;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    
    ${({ $isInputFocused }) => $isInputFocused && `
        border-top: 1px solid #9bafd3;
        border-bottom: 1px solid #9bafd3;
        border-left: 1px solid #9bafd3;
    `}
`;

export const ButtonCleanInput = styled.div`
    width: 30px;
    height: 45px;
    background-color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ $isInputFocused }) => $isInputFocused && `
        border-top: 1px solid #9bafd3;
        border-bottom: 1px solid #9bafd3;
        border-right: 1px solid #9bafd3;
    `}
`;

export const ButtonSendContent = styled.div`
    width: 40%;
    height: 100%;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
        width: 100%;
    }
`;

export const ButtonSend = styled.button`
    width: 100%;
    height: 45px;
    background-color: #37517E;
    border: none;
    border-radius: 5px;
    color: #ffffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .25s ease-in-out;

    &:hover {
        cursor: pointer;
        opacity: 0.85;
        background-color: #3d63a5d5;
        border: 1px solid #9bafd3;
        transition: .35s ease-in;
    }

    @media (max-width: 375px) {
        width: 100%;
    }
`;

export const ButtonText = styled.span`
    font-weight: 400;
    font-size: 14px;
`;