import styled from 'styled-components';

export const RequestContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export const WelcomeContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const WelcomeContent = styled.div`
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 25px;
`;

export const WelcomeText = styled.span`
    font-size: 60px;
    font-weight: 500;
    font-family: 'Poppins',Verdana, Geneva, Tahoma, sans-serif;
    padding: 10px 0;
    color: #37517E;

    @media (max-width: 985px) {
        font-size: 40px;
    }

    @media (max-width: 410px) {
        font-size: 30px;
    }
`;

export const WelcomeSubText = styled.span`
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins',Verdana, Geneva, Tahoma, sans-serif;
    color: #37517ebc;

    @media (max-width: 985px) {
        font-size: 18px;
        text-align: center;
    }

    @media (max-width: 410px) {
        font-size: 15px;
    }
`;

export const RequestsArea = styled.div`
    width: 100%;
    max-width: 1300px;
    min-width: 340px;
    height: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 1100px) {
        max-width: 550px;
    }
`;

export const RequestText = styled.span`
    padding-top: 20px;
    text-align: center;
    font-weight: 500;
    color: #37517ebc;
`;

export const RequestCard = styled.div`
    width: auto;
    height: auto;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 3px #86868696;
    border-radius: 15px;
    margin: 10px 15px;
    padding: 10px;
`;

export const NumPedidoContent = styled.div`
    width: 100%;
    height: 70px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 600px) {
        padding-bottom: 0px;
    }
`;

export const NumPedido = styled.span`
    font-weight: 500;
    font-size: 20px;
    color: #37517E;
    display: flex;
    align-items: center;
    gap: 5px;

    p {
        color: #222;
    }

    @media (max-width: 600px) {
        font-size: 15px;
    }
`;

export const DatasContent = styled.div`
    width: 95%;
    background-color: bisque;
    height: auto;
    display: flex;
    justify-content: center;
    padding-top: 5px;
`;

export const DatasRequest = styled.span`
    font-size: 13px;
    font-weight: 400;
    color: #0000009a;

    @media (max-width: 330px) {
        font-size: 11px;
    }
`;

export const DatasRequestTransfer = styled.div`
    width: 100%;
    height: auto;
    display: flex;

    @media (max-width: 1100px) {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .cancaled {
        width: 20%;
    }
`;

export const DataTransferContent = styled.div`
    width: 100%;
    height: auto;
    min-height: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;

    @media (max-width: 600px) {
        margin: 2px 0;
    }
`;

export const Icon = styled.div`
    height: 35px;  
    position: relative;

    img {
        object-fit: cover;
        width: auto;
        height: 100%;
    }

    .icon-cancel {
        position: absolute;
        right: -5px;
        bottom: -7px;
    }
`;

export const Separator = styled.div`
    width: 100%;
    height: 1px;
    margin: 25px 0;
    background-color: #37517E;

    @media (max-width: 1100px) {
        order: 3;
        height: 60px;
        width: 1px;
    }

    @media (max-width: 600px) {
        order: 3;
        height: 15px;
        width: 1px;
    }
`;

export const DatasRequestTransferInf = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px;
    max-width: 120px;

    @media (max-width: 1100px) {
        order: 2;
    }
`;

export const TranferInfTitle = styled.span`
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    min-height: 50px;
    color: #37517E;

    @media (max-width: 500px) {
        min-height: 1px;
    }
`;

export const TranferInfSubTitle = styled.span`
    color: #0000009a;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
`;