import {
    RequestContainer,
    Content,
    WelcomeContent,
    WelcomeSubText,
    RequestsArea,
    WelcomeContainer,
    RequestText
} from './styles';
import { useEffect, useState } from 'react';
import Card from './Cards';

export default function RequestComponent({ onClickSearchOrdens, filteredOrders  }) {

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const data = filteredOrders;
        setOrders(data);
    }, [orders, filteredOrders]);

    return (
        <RequestContainer>
            {onClickSearchOrdens === false ?
                (
                    <WelcomeContainer>
                        <WelcomeContent>
                            <WelcomeSubText>Digite seu CPF ou número de telefone para rastrear seus pedidos.</WelcomeSubText>
                        </WelcomeContent>
                    </WelcomeContainer>
                ) : (
                    <Content>
                        <RequestsArea>
                            {filteredOrders.length > 0 ? (
                                filteredOrders.map((item, index) => (
                                    <Card key={index} item={item} />
                                ))
                            ) : (
                                <RequestText>Nenhum pedido encontrado.</RequestText>
                            )}
                        </RequestsArea>
                    </Content>
                )
            }
        </RequestContainer>
    );
}