import {
    RequestCard,
    NumPedidoContent,
    NumPedido,
    Separator,
    DatasRequestTransfer,
    DatasRequestTransferInf,
    DataTransferContent,
    TranferInfSubTitle,
    TranferInfTitle,
    Icon
} from './styles';
import { format } from 'date-fns';
import Calender from '../assets/calender.png';
import MapPoint from '../assets/map_point.png';
import Package from '../assets/package.png';
import PackageCheck from '../assets/package_check.png';
import Truck from '../assets/truck.png';
import Padlock from '../assets/padlock.png';
import { X } from 'lucide-react';

export default function Card({ item }) {

    if (!item || typeof item !== 'object') {
        return <p>Dados inválidos.</p>;
    }

    function formatDate(dateFormatted) {
        const date = new Date(dateFormatted);
        const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        return format(utcDate, 'dd/MM/yyyy');
    }

    return (
        <RequestCard>
            <NumPedidoContent>
                <NumPedido>Pedido: <p>{item.numero} - {item.status}</p></NumPedido>
            </NumPedidoContent>
            <DatasRequestTransfer>
                <DataTransferContent {...(item.status === 'Cancelado' && { className: 'cancaled' })}>
                    <Icon>
                        <img src={Calender} alt='' />
                    </Icon>
                    <Separator />
                    <DatasRequestTransferInf>
                        <TranferInfTitle>Data do pedido:</TranferInfTitle>
                        <TranferInfSubTitle>
                            {item.data === '' || item.data === null ? '---' : formatDate(item.data)}
                        </TranferInfSubTitle>
                    </DatasRequestTransferInf>
                </DataTransferContent>
                <DataTransferContent {...(item.status === 'Cancelado' && { className: 'cancaled' })}>
                    <Icon>
                        <img src={Package} alt='' />
                        {item.status === 'Cancelado' && (
                            <X size={50} strokeWidth={2} color='red' className='icon-cancel' />
                        )}
                    </Icon>
                    <Separator />
                    <DatasRequestTransferInf>
                        <TranferInfTitle>Forma de envio:</TranferInfTitle>
                        <TranferInfSubTitle>
                            {item.status === 'Cancelado' ?
                                'Pedido cancelado'
                                :
                                item.forma_envio === '' ? '---' : item.forma_envio
                            }
                        </TranferInfSubTitle>
                    </DatasRequestTransferInf>
                </DataTransferContent>
                {item.status !== 'Cancelado' && (
                    <>
                        <DataTransferContent>
                            <Icon>
                                <img src={Truck} alt='' />
                            </Icon>
                            <Separator />
                            <DatasRequestTransferInf>
                                <TranferInfTitle>Previsão de Entrega:</TranferInfTitle>
                                <TranferInfSubTitle>
                                    {item.previsao_entrega === null ? '---' : formatDate(item.previsao_entrega)}
                                </TranferInfSubTitle>
                            </DatasRequestTransferInf>
                        </DataTransferContent>
                        <DataTransferContent>
                            <Icon>
                                <img src={Padlock} alt='' />
                            </Icon>
                            <Separator />
                            <DatasRequestTransferInf>
                                <TranferInfTitle>Código de Envio:</TranferInfTitle>
                                <TranferInfSubTitle>
                                    {item.codigo_rastreio === '' ? '---' : item.codigo_rastreio}
                                </TranferInfSubTitle>
                            </DatasRequestTransferInf>
                        </DataTransferContent>

                        {item.historico && item.historico.map((historicoItem, index) => {
                            const [data, ...descricaoArray] = historicoItem.split(' - ');
                            const descricao = descricaoArray.join(' - ');
                            return (
                                <DataTransferContent key={index}>
                                    <Icon>
                                        <img src={MapPoint} alt='' />
                                    </Icon>
                                    <Separator />
                                    <DatasRequestTransferInf>
                                        <TranferInfTitle>{data}</TranferInfTitle>
                                        <TranferInfSubTitle>{descricao}</TranferInfSubTitle>
                                    </DatasRequestTransferInf>
                                </DataTransferContent>
                            );
                        })}

                        {item.status === 'Finalizado' && (
                            <DataTransferContent>
                                <Icon>
                                    <img src={PackageCheck} alt='' />
                                </Icon>
                                <Separator />
                                <DatasRequestTransferInf>
                                    <TranferInfTitle>Pedido entregue</TranferInfTitle>
                                    <TranferInfSubTitle>{item.data_entrega === null ? '' : formatDate(item.data_entrega)}</TranferInfSubTitle>
                                </DatasRequestTransferInf>
                            </DataTransferContent>
                        )}
                    </>
                )}
            </DatasRequestTransfer>
        </RequestCard>
    );
};